<template>
	<div>
		<ContentHeader title="Laporan Farmasi" subTitle="Laporan Mutasi Obat" url="laporan-farmasi" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
          <div class="card-header">
            <h3 class="card-title">Data Laporan</h3>
          </div>
          <div class="card-body" style="display: block;">
            <div class="row align-items-center mt-2">
              <div class="col-lg-2">
      					<span>Periode Awal</span>
      					<input type="date" class="form-control" v-model="periode_awal">
      				</div>
      				<div class="col-lg-2">
      					<span>Periode Akhir</span>
      					<input type="date" class="form-control" v-model="periode_akhir">
      				</div>
      				<div class="col-lg-1 pt-4">
      					<button class="btn btn-primary" @click="getDataResult">Filter</button>
      				</div>
              <div class="col-lg-2"></div>
              <div class="col-lg-5">
              	<span>Cabang</span>
              	<multiselect 
                  v-model="cabang_select_value"
                  deselect-label="Can't remove this value"
                  track-by="nama"
                  label="nama"
                  placeholder=""
                  :options="cabang"
                  :loading="loading_cabang"
                  :allow-empty="false"
                  @update:model-value="getDataResult"
                >
                </multiselect>
              </div>
            </div>
            <table class="table table-bordered table-hover mt-4">
              <thead>
                <tr>
                  <th scope="col" class="text-sm">NO</th>
                  <th scope="col" class="text-sm">TANGGAL</th>
                  <th scope="col" class="text-sm">TANGGAL ACC</th>
                  <th scope="col" class="text-sm">NO FAKTUR</th>
                  <th scope="col" class="text-sm">KODE PRODUK</th>
                  <th scope="col" class="text-sm">HARGA JUAL</th>
                  <th scope="col" class="text-sm">JUMLAH DIMUTASI</th>
                  <th scope="col" class="text-sm">DISKON</th>
                  <th scope="col" class="text-sm">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in data_result" :key="row.id">
                  <td class="text-sm p-2" scope="row" >{{ index + 1 }}</td>
                  <td class="text-sm p-2" scope="row" >{{ row.pembelian.created_at.slice(0, 10) }}</td>
                  <td class="text-sm p-2" scope="row" >{{ row.pembelian.updated_at.slice(0, 10) }}</td>
                  <td class="text-sm p-2" scope="row" >{{ row.pembelian.nomor_faktur }}</td>
                  <td class="text-sm p-2" scope="row" >{{ row.produk.kode }}</td>
                  <td class="text-sm p-2" scope="row" >{{ format_nominal(row.harga_beli) }}</td>
                  <td class="text-sm p-2" scope="row" >{{ row.jumlah }}</td>
                  <td class="text-sm p-2" scope="row" >{{ convertToNominal(row.jumlah * row.harga_beli, row.diskon) }}</td>
                  <td class="text-sm p-2" scope="row" >{{ format_nominal(row.total) }}</td>
                </tr>
              </tbody>
            </table>
            <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
            <p class="text-center mt-2">{{ cek_data }}</p>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
          </div>
          <!-- /.card-footer-->
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'
import format_nominal from '@/format_nominal'

export default{
  components: {
    ContentHeader,
    Multiselect
  },
  setup(){
    const user = computed(() => store.getters['auth/user'])
    const data_result = ref([])
    const cek_data = ref('')
    const memuat_data = ref(false)
    const periode_awal = ref('')
    const periode_akhir = ref('')
    const total = ref('')

    const getDataResult = async () => {
      data_result.value = []
      total.value = ''
      memuat_data.value = true
      cek_data.value = ''
			let tanggal_awal = ''
      let tanggal_akhir = ''
      let cabang_id = ''

      if (periode_awal.value == '' && periode_akhir.value == '') {
      	tanggal_awal = 'emptyParameter'
      	tanggal_akhir = 'emptyParameter'
      } else {
      	tanggal_awal = periode_awal.value
      	tanggal_akhir = periode_akhir.value
      }

      if (cabang_select_value.value == '') {
        cabang_id = 'emptyParameter'
      } else {
        cabang_id = cabang_select_value.value.id
      }

      let { data } = await axios.get(`api/laporan/mutasiStok/${user.value.cabang_id}/${cabang_id}/${tanggal_awal}/${tanggal_akhir}`)
      console.table(data)
      if (data == 'kosong') {
        cek_data.value = 'Data Masih Kosong'
        memuat_data.value = false
        data_result.value = []
      } else if (data == 'choose_cabang') {
        cek_data.value = 'Pilih Cabang'
        memuat_data.value = false
        data_result.value = []
      } else {
        memuat_data.value = false
        cek_data.value = ''
        data_result.value = data.data
        total.value = data.total
      }
    }

		const cabang_select_value = ref('')
		const cabang = ref([])
		const loading_cabang = ref(true)
		const getCabang = async () => {
			let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

			if (response.data.jenis == 'pusat') {
				let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)
				
				if (data != 'kosong') {
					cabang.value = data
					loading_cabang.value = false
				} else {
					cabang.value = []
					loading_cabang.value = false
				}
			}
		}

    const convertToNominal = (harga_awal, diskon) => {

      if (diskon > 0 && diskon <= 100) {
        return format_nominal((harga_awal * diskon) / 100)
      } else if (diskon == 0 || diskon == null) {
        return '-'
      } else {
        return format_nominal(diskon)
      }
    }


    onMounted(() => {
      getCabang()
      getDataResult()
    })

    return {
      data_result, cek_data, memuat_data, getDataResult, format_nominal, total, periode_awal, periode_akhir, cabang_select_value, cabang, loading_cabang, convertToNominal
    }
  }
}
</script>